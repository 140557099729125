import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navbar, Nav, NavItem, Image, NavDropdown, MenuItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import UserNavItem from "./UserNavItem";
import Breadcrumb from "../../containers/Layout/BreadcrumbContainer";
import byvaapen from "../../images/byvaap-cmyk.png";
import byvaapen2x from "../../images/byvaap-cmyk@2x.png";
import byvappen3x from "../../images/byvaap-cmyk@3x.png";
import { ADMIN_VIEW, USER_VIEW } from "actions/loginAction";

import "./NavigationBar.css";
import Tag from "components/Common/Tag";
import { getEnvironment, isProduction } from "constants/environment";

const NavigationBar = ({ brukerId, isAdmin, isBruker, appView, onLogout, brukernavn, onAppViewSelected, history }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{ position: "fixed", top: "0", width: "100%", zIndex: "1030" }}>
      <Navbar fluid bsStyle="bym">
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <Image className="byvaap-cmyk" src={byvaapen} srcSet={`${byvaapen2x} 2x,${byvappen3x} 3x`} />
              Utleie av grunn og eiendom
              {!isProduction() && <Tag tagStyle="orange">{getEnvironment().toUpperCase()}</Tag>}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>

        <Navbar.Collapse>
          {isAdmin && (
            <Nav>
              <NavDropdown
                open={isOpen}
                onToggle={open => {
                  setIsOpen(open);
                }}
                title={`${appView === ADMIN_VIEW ? "admin" : "bruker"}-grensesnitt`}
                value={appView}
                onSelect={view => {
                  setIsOpen(false);
                  history.push("/");
                  onAppViewSelected(view);
                }}
              >
                <MenuItem eventKey={ADMIN_VIEW}>Admingrensesnitt</MenuItem>
                <MenuItem eventKey={USER_VIEW}>Brukergrensesnitt</MenuItem>
              </NavDropdown>
            </Nav>
          )}
          {brukerId != null && (
            <Nav pullRight>
              {isAdmin && appView === ADMIN_VIEW && (
                <React.Fragment>
                  <LinkContainer to="/admin">
                    <NavItem className="navbar-item-small" eventKey="1">
                      Admin
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/fakturaadministrasjon">
                    <NavItem className="navbar-item-small" eventKey="1">
                      Fakturaer
                    </NavItem>
                  </LinkContainer>
                </React.Fragment>
              )}
              {(isBruker || isAdmin) && appView === USER_VIEW && (
                <React.Fragment>
                  <LinkContainer to="/eiendommer">
                    <NavItem className="navbar-item-small" eventKey="1">
                      Eiendommer
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/leieobjekter">
                    <NavItem className="navbar-item-small" eventKey="2">
                      Leieobjekter
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/kontrakter">
                    <NavItem className="navbar-item-medium" eventKey="3">
                      Kontrakter
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/okonomi">
                    <NavItem className="navbar-item-medium" eventKey="4">
                      Økonomi
                    </NavItem>
                  </LinkContainer>
                </React.Fragment>
              )}
              <UserNavItem eventKey="3" brukerId={brukernavn} onLogout={onLogout} />
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
      <Breadcrumb />
    </div>
  );
};

NavigationBar.propTypes = {
  brukerId: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  isBruker: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  brukernavn: PropTypes.string
};

export default NavigationBar;
